import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Layout = ({ children }) => (
  <div className="content-wrapper flex flex-col font-sans min-h-screen">
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/lxu4jjs.css" />
    </Helmet>
    <main className="flex flex-col flex-1 items-center justify-center max-w-6xl mx-auto px-4 py-8 md:p-8 w-full">
      {children}
    </main>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
