import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSignInAlt,
  faHeart,
  faBook,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faGithub } from '@fortawesome/free-brands-svg-icons';

library.add(faSignInAlt, faDiscord, faGithub, faHeart, faBook, faHome);

const Icon = ({ name, ...options }) => (
  <FontAwesomeIcon icon={name} {...options} />
);

Icon.propTypes = {
  name: PropTypes.any.isRequired,
  options: PropTypes.object,
};

Icon.defaultProps = {
  options: {},
};

export default Icon;
