import React from 'react';
import Icon from './Icon';
import { Link } from 'gatsby';

const Button = ({ type, children, iconName, ...props }) => {
  if (type === 'a') {
    return (
      <a {...props} className="button">
        <div className="button-inside" />
        {iconName && <Icon name={iconName} className="mr-2 icon" />}
        <span>{children}</span>
      </a>
    );
  }
  return (
    <Link {...props} className="button">
      <div className="button-inside" />
      {iconName && <Icon name={iconName} className="mr-2 icon" />}
      <span>{children}</span>
    </Link>
  );
};

export default Button;
